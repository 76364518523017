import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductCategory = _resolveComponent("ProductCategory")!
  const _component_ProductList = _resolveComponent("ProductList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_ProductCategory)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_ProductList, {
        products: _ctx.products,
        productCount: _ctx.productCount,
        onInfiniteHandler: _ctx.infiniteHandler
      }, null, 8, ["products", "productCount", "onInfiniteHandler"])
    ])
  ]))
}