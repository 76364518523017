
import { computed, defineComponent } from "vue";
import { Category } from "@/types/category/Category.interface";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const categoryUrlkey = route.params.category;
    const getCategoryNextLevel = (category: Category) => {
      const redirectUri = `/${route.params.initialId}/product/${category.urlKey}?sessionId=${route.query.sessionId}`;

      if (route.params.category !== category.urlKey) {
        router.push(redirectUri);
      }
    };

    return {
      categorySelected: computed(() => store.getters.getCategorySelected),
      getCategoryNextLevel,
      categoryUrlkey,
      brandId: computed(() => store.getters.getBrand.brandId),
    };
  },
});
