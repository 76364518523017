import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb468ba8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overflow-x-auto p-d-flex p-jc-start" }
const _hoisted_2 = { class: "p-buttonset p-text-left p-d-inline-flex btn-group-category w-100" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorySelected, (category, cindex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: cindex,
          class: "btn-category p-py-3 mx-2 p-my-auto p-text-center",
          onClick: ($event: any) => (_ctx.getCategoryNextLevel(category))
        }, [
          (category && category.name)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([
            'unactive ',
            {
              active:
                category.urlKey == 'All' || category.urlKey == _ctx.categoryUrlkey,
            },
          ])
              }, _toDisplayString(category.name), 3))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}